<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/TemplateCategory/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="label" class="col-sm-2 col-form-label">名稱</label>
							<div class="col-sm-10">
								<input v-model="model.label"
									type="text"
									class="form-control"
									id="label"
									name="label"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue'
	import { addTemplateCategory } from '@/api/TemplateCategory.js'

	export default {
		name: 'ProjectCreate',
		components: {
			LayoutDiv
		},
		data() {
			return {
				model: {
					type: 0,
					label: '',

				},
				isSaving: false,
			};
		},
		methods: {
			handleSave() {
				this.isSaving = true

				addTemplateCategory(this.model).then(rep => {

					this.isSaving = false;
					this.reset();
					return rep;
				})
				.catch(error => {
					this.isSaving = false
					return error
				});
			},
			reset() {
					this.model.type = 0;
					this.model.label = "";

			}
		}
	};
</script>
