import { apiGet, apiPost } from './index'

export async function listTemplateCategory(data) {
	return await apiPost("/api/TemplateCategory/list", data);
}

export async function fullListTemplateCategory(data) {
	return await apiPost("/api/TemplateCategory/fulllist", data);
}

export function getTemplateCategory(data) {
	let url = "/api/TemplateCategory/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postTemplateCategory(data) {
	return apiPost("/api/TemplateCategory/edit", data);
}

export function addTemplateCategory(data) {
	return apiPost("/api/TemplateCategory/add", data);
}

export function deleteTemplateCategory(data) {
	return apiGet("/api/TemplateCategory/delete/" + data);
}
